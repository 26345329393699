import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";
import LogoDark from "../../icons/LogoDark";
import Typography from "../Typography";
import "./index.scss";

interface IProps {
  title: string;
}

export default function PageHeader({ title }: IProps): ReactElement {
  return (
    <header className="pq-page_header">
      <Helmet>
        <html lang="en" />
        <title>PharmIQ - {title}</title>
      </Helmet>
      <div className="pq-page_header-logo">
        <LogoDark />
      </div>
      <Typography variant="title_small" color="#0B2143">{title}</Typography>
    </header>
  );
}
